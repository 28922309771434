.action-bar {
    margin-bottom: 1em;
}

.action-bar .form-horizontal .btn-group .btn {
    margin-right: 0;
}

.action-bar .form-horizontal label,
.action-bar .btn,
.action-bar select,
td.action-bar a {
    margin-right: 1em;
}

.btn svg, .btn i {
    margin-right: .2em;
}

.btn-app svg {
    display: block;
    font-size: 1.8em;
    margin: auto;
}

a:not([href]).btn-tool {
    color: #adb5bd;
}

a.disabled {
    pointer-events: none;
}

.card-footer .pagination {
    margin-bottom: 0;
}

.datatable-nb-entries {
    max-width: 5em;
}

.datatable-nb-entries {
    display: inline-block;
    width: auto;
    margin-left: .5em;
}

.dataTables_info {
    line-height: 2.5em;
}

.editor-toolbar svg {
    margin-right: .5em;
}

.item-clickable,
.page-link {
    cursor: pointer;
}

.custom-checkbox {
    margin-bottom: 1em;;
}

.bold {
    font-weight: bold;
}

.toasts-top-right {
    top: 1em;
    right: 1em;
}